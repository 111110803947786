import { useState, useEffect } from 'react'

// import { SiConsul } from 'react-icons/si'
// import { AiOutlineGlobal } from 'react-icons/ai'
// import { BsPhoneVibrate } from 'react-icons/bs'
// import { CgMenuGridO } from 'react-icons/cg'
//import logo from '../../assets/images/eggplant-svgrepo-com.svg'
// import '../../assets/styles.css'
// import logo from '../../assets/images/tvp.jpg'
import React from 'react';

interface SliderProps {
  images: string[];
}

//const Slider = () => {
// const Slider: React.FC<SliderProps> = ({ images }) => {
const Slider: React.FC<SliderProps> = ({ images }) => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState('');
  // const handlePrevSlide = () => {
  //   console.log("prev");
  //   setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
  // };

  // const handleNextSlide = () => {
  //   console.log("next");
  //   setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  // };

  const handlePrevSlide = () => {
    setCurrentSlide(prevSlide => {
      setDirection('slide-left');
      return prevSlide === 0 ? images.length - 1 : prevSlide - 1;
    });
  };

  const handleNextSlide = () => {
    setCurrentSlide(prevSlide => {
      setDirection('slide-right');
      return prevSlide === images.length - 1 ? 0 : prevSlide + 1;
    });
  };


  useEffect(() => {
    const interval = setInterval(() => {
      handleNextSlide();
    }, 10000); // Change the interval time as needed (10 seconds in this case)

    return () => clearInterval(interval);
  }, [currentSlide]); // Trigger effect whenever currentSlide changes

  // const [isTopNavCollapsed, setIsTopNavCollapsed] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const isScrolled = window.scrollY > 60;
  //     setIsTopNavCollapsed(isScrolled);
  //   };

  //   // Listen for scroll and load events
  //   window.addEventListener('scroll', handleScroll);
  //   window.addEventListener('load', handleScroll);

  //   // Cleanup the event listeners
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     window.removeEventListener('load', handleScroll);
  //   };
  // }, []);

  // const handlePrevClick = () => {
  //   console.log("prev");
  //   setCurrentSlide((prevSlide) => Math.max(0, prevSlide - 1));
  // };

  // const handleNextClick = () => {
  //   console.log("next");
  //   setCurrentSlide((prevSlide) => prevSlide + 1);
  // };

  // // remove navBar on small screens
  // const [active, setActive] = useState('navBarMenu');
  // const showNavBar = () => {
  //   setActive('navBarMenu showNavBar');
  // }
  // const removeNavBar = () => {
  //   setActive('navBarMenu');
  // }

  // // add bgcolor on second nabVar
  // const [noBg, addBg] = useState('navBarTwo');
  // const addBgColor = () => {
  //   if (window.scrollY >= 10) {
  //     addBg('navBarTwo navbar_With_Bg');
  //   } else {
  //     addBg('navBarTwo')
  //   }
  // }
  // window.addEventListener('scroll', addBgColor)

  return (
    <div className="container py-32 px-4 sm:px-8 xl:px-4" style={{paddingBottom:'1rem'}}>
      <div className="carousel">
          <div className={`slide ${direction === 'left' ? 'fade-left' : direction === 'right' ? 'fade-right' : ''}`}>
          <img src={images[currentSlide]} alt={`Slide ${currentSlide + 1}`} />

          <div className="spot-indicator">
          {images.map((_, index) => (
            <button
              key={index}
              className={index === currentSlide ? 'active' : ''}
              onClick={() => setCurrentSlide(index)}
            ></button>
          ))}
          </div>
          <button type="button" className="carousel-prev"  id="carousel-prev" onClick={handlePrevSlide}>
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button type="button" className="carousel-next" id="carousel-next" onClick={handleNextSlide}>
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
        </div>
        {/* <button onClick={handlePrevSlide}>Previous</button>
        <button onClick={handleNextSlide}>Next</button> */}
      </div>
    </div>

    
    // <div className="container px-4 sm:px-8 xl:px-4">
    //   <div id="default-carousel" className="relative w-full" data-carousel="slide">
    //     {/* Carousel wrapper */}
    //     <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
    //       {/* Item 1 */}
    //       <div className="hidden duration-700 ease-in-out" data-carousel-item>
    //         <img src={logo} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
    //       </div>
    //       {/* Add more items here */}

    //       {/* Slider indicators */}
    //       <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
    //         {[...Array(5).keys()].map((index) => (
    //           <button key={index} type="button" className="w-3 h-3 rounded-full" aria-current={index === 0} aria-label={`Slide ${index + 1}`} data-carousel-slide-to={index}></button>
    //         ))}
    //       </div>

    //       {/* Slider controls */}
    //       <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev id="data-carousel-prev" onClick={handlePrevClick}>
    //         {/* Prev button contents */}
    //       </button>
    //       <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next id="data-carousel-next" onClick={handleNextClick}>
    //         {/* Next button contents */}
    //       </button>
    //     </div>
    //   </div>
    // </div>


    // <div className="container px-4 sm:px-8 xl:px-4">
    // <div id="default-carousel" className="relative w-full" data-carousel="slide">
    //     {/* <!-- Carousel wrapper --> */}
    //     <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
    //         {/* <!-- Item 1 --> */}
    //         <div className="hidden duration-700 ease-in-out" data-carousel-item>

    //             <img src={logo} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
    //         </div>
    //         {/* <!-- Item 2 --> */}
    //         <div className="hidden duration-700 ease-in-out" data-carousel-item>
    //             <img src={logo} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
    //         </div>
    //         {/* <!-- Item 3 --> */}
    //         <div className="hidden duration-700 ease-in-out" data-carousel-item>
    //             <img src={logo} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
    //         </div>
    //         {/* <!-- Item 4 --> */}
    //         <div className="hidden duration-700 ease-in-out" data-carousel-item>
    //             <img src={logo} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
    //         </div>
    //         {/* <!-- Item 5 --> */}
    //         <div className="hidden duration-700 ease-in-out" data-carousel-item>
    //             <img src={logo} className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
    //         </div>
    //     </div>
    //     {/* <!-- Slider indicators --> */}
    //     <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
    //         <button type="button" className="w-3 h-3 rounded-full" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
    //         <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
    //         <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
    //         <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
    //         <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 5" data-carousel-slide-to="4"></button>
    //     </div>
    //     {/* <!-- Slider controls --> */}
    //     <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev id="data-carousel-prev" onClick={handlePrevClick}>
    //         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
    //             <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
    //                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
    //             </svg>
    //             <span className="sr-only">Previous</span>
    //         </span>
    //     </button>
    //     <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next id="data-carousel-next" onClick={handleNextClick}>
    //         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
    //             <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
    //                 <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
    //             </svg>
    //             <span className="sr-only">Next</span>
    //         </span>
    //     </button>
    // </div>
    // </div>
  )
}

export default Slider
