import { useState, useEffect } from 'react'
import { SiConsul } from 'react-icons/si'
import { AiOutlineGlobal } from 'react-icons/ai'
import { BsPhoneVibrate } from 'react-icons/bs'
import { CgMenuGridO } from 'react-icons/cg'
import logo from '../../assets/images/logo2.svg' //main logo

import Dropdown from 'react-bootstrap/Dropdown';




const Navbar2 = () => {

  const [isTopNavCollapsed, setIsTopNavCollapsed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 60;
      setIsTopNavCollapsed(isScrolled);
    };

    // Listen for scroll and load events
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('load', handleScroll);

    // Cleanup the event listeners
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('load', handleScroll);
    };
  }, []);

  // remove navBar on small screens
  const [active, setActive] = useState('navBarMenu');
  const showNavBar = () => {
    setActive('navBarMenu showNavBar');
  }
  const removeNavBar = () => {
    setActive('navBarMenu');
  }

  // menu
  const [isMenuOpen, setMenu] = useState(true);
  const handleMenu = () => {
    console.log(isMenuOpen);
    setMenu(!isMenuOpen);
  }


  // add bgcolor on second nabVar
  const [noBg, addBg] = useState('navBarTwo');
  const addBgColor = () => {
    if (window.scrollY >= 10) {
      addBg('navBarTwo navbar_With_Bg');
    } else {
      addBg('navBarTwo')
    }
  }
  window.addEventListener('scroll', addBgColor)

  return (






    <nav className={isTopNavCollapsed ? 'navbar fixed-top top-nav-collapse' : 'navbar fixed-top'}>
      <div className="container sm:px-4 lg:px-8 flex flex-wrap items-center justify-between lg:flex-nowrap">
          <a className="inline-block mr-4 py-0.5 text-xl whitespace-nowrap hover:no-underline focus:no-underline" href="index.html">
              <img style={{width:'70px'}} src={logo} className="App-logo fadeDropdown" alt="logo" />
              {/* Tailor Text */}


          </a>
          <svg viewBox="0 0 180 20" xmlns="http://www.w3.org/2000/svg" style={{width:'14rem'}}>
                <style>
                  {`
                    .logob {
                      font: italic 13px sans-serif;
                    }
                    .logoa {
                      font: bold 18px serif;
                    }
                    .logoc {
                      font: italic 12px serif;
                      fill: blue;
                    }
                  `}
                </style>
                <text x="2" y="12" className="logoa">Joice</text>
                <text x="40" y="8" className="logob">&apos;s</text>
                <text x="48" y="17" className="logoc">Profile</text>
              </svg>
          <button className="background-transparent rounded text-xl leading-none hover:no-underline focus:no-underline lg:hidden lg:text-gray-400" type="button" data-toggle="offcanvas">
              <span className="navbar-toggler-icon inline-block w-8 h-8 align-middle"  onClick={handleMenu}></span>
          </button>
          <div className={`navbar-collapse offcanvas-collapse lg:flex lg:flex-grow lg:items-center ${isMenuOpen ? 'hidden' : 'block'}`} id="navbarsExampleDefault">

              <ul className="pl-0 mt-3 mb-2 ml-auto flex flex-col list-none lg:mt-0 lg:mb-0 lg:flex-row">
                  <li>
                      <a className="nav-link page-scroll active" href="#header">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li>
                      <a className="nav-link page-scroll" href="#ability">Ability</a>
                  </li>
                  <li>
                      <a className="nav-link page-scroll" href="#interest">Interest</a>
                  </li>
                  {/* drop down menu */}
                  <li className="dropdowna">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic" className="nav-link float-right">
                        Demo
                      {/* // <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Drop</a> */}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#demo" className="nav-link">Demo 1</Dropdown.Item>
                        <Dropdown.Item href="#demo" className="nav-link">Demo 2</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>               
                  </li>
                  <li>
                      <a className="nav-link page-scroll" href="#contact">Contact</a>
                  </li>


              </ul>
              {/* <span className="block lg:ml-3.5">
                  <a className="no-underline" href="#your-link">
                      <i className="fab fa-apple text-indigo-600 hover:text-pink-500 text-xl transition-all duration-200 mr-1.5"></i>
                  </a>
                  <a className="no-underline" href="#your-link">
                      <i className="fab fa-android text-indigo-600 hover:text-pink-500 text-xl transition-all duration-200"></i>
                  </a>
              </span> */}
          </div> 
      </div> 
    </nav>


  )
}

export default Navbar2
