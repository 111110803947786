import React from 'react';
import { useState } from 'react'
import { SiConsul } from 'react-icons/si'
import { AiOutlineGlobal } from 'react-icons/ai'
import { BsPhoneVibrate } from 'react-icons/bs'
import { CgMenuGridO } from 'react-icons/cg'
import logo from '../../assets/logo.png'
import icon1 from '../../assets/images/features-icon-1.svg'
import icon2 from '../../assets/images/features-icon-2.svg'
import icon3 from '../../assets/images/features-icon-3.svg'

//const Contact: React.FC = () => { 
const Interest = () => {

  return (
    <div id="interest" className="cards-1">
            <div className="container px-4 sm:px-8 xl:px-4">
            <h2 style={{margin:'10px'}}>Interest</h2>
                <div className="card">
                    <div className="card-image">
                        <img src={icon1} alt="alternative" />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Design</h5>
                        <p className="mb-4">Interest on design via different environments</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon2} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Innovation</h5>
                        <p className="mb-4">Optimized code work through inspire and innovative technology with new ideas from current environments</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon3} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Learning</h5>
                        <p className="mb-4">Learning different new techniques includes languages and new ideas</p>
                    </div>
                </div>
            </div> 
        </div>
  );
}

export default Interest