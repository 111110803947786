import { useState } from 'react'
import { SiConsul } from 'react-icons/si'
import { AiOutlineGlobal } from 'react-icons/ai'
import { BsPhoneVibrate } from 'react-icons/bs'
import { CgMenuGridO } from 'react-icons/cg'
import logo from '../../assets/logo.png'
import icon1 from '../../assets/images/features-icon-1.svg'
import icon2 from '../../assets/images/features-icon-2.svg'
import icon3 from '../../assets/images/features-icon-3.svg'
import icon4 from '../../assets/images/features-icon-4.svg'
import icon5 from '../../assets/images/features-icon-5.svg'
import icon6 from '../../assets/images/features-icon-6.svg'

const Navbar2 = () => {

  // remove navBar on small screens
  const [active, setActive] = useState('navBarMenu');
  const showNavBar = () => {
    setActive('navBarMenu showNavBar');
  }
  const removeNavBar = () => {
    setActive('navBarMenu');
  }

  // add bgcolor on second nabVar
  const [noBg, addBg] = useState('navBarTwo');
  const addBgColor = () => {
    if (window.scrollY >= 10) {
      addBg('navBarTwo navbar_With_Bg');
    } else {
      addBg('navBarTwo')
    }
  }
  window.addEventListener('scroll', addBgColor)

  return (
    <div id="ability" className="cards-1">
            <div className="container px-4 sm:px-8 xl:px-4">
            <h2 style={{margin:'10px'}}>Ability</h2>
                <div className="card">
                    <div className="card-image">
                        <img src={icon1} alt="alternative" />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Research</h5>
                        <p className="mb-4">Research and analysis from market data and establish strategies for benefit for the Organisation</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon2} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Development</h5>
                        <p className="mb-4">Analysis and product development which should be more benefit for business operation</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon3} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Project Management</h5>
                        <p className="mb-4">Optimized time, cost, resource which produce with a good quality products</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon4} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Change Management</h5>
                        <p className="mb-4">Interanl change and external change of stakeholders which carry out the success of innovations</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon5} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Business Development</h5>
                        <p className="mb-4">Communications between stakeholders and bargain ability with negotiation between business</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon6} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Technology Management</h5>
                        <p className="mb-4">Adopt technologies which most suitable for the Organisation intends to cost effective operation</p>
                    </div>
                </div>
            </div> 
        </div>
  )
}

export default Navbar2
