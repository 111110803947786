import * as React from 'react';
import { Static, Type } from '@sinclair/typebox'
//import logo from './logo.svg';
//import './App.css';
//import './main.scss'
// import Navbar from './Components/Navbar/Navbar'
// import StatusCard from './Components/StatusCard'
// import Sidebar from './Components/New/Sidebar';
import Navbar2 from './Components/Navbar2/Navbar2';
import Slider from './Components/Navbar2/Slider';
import Interest from './Components/Present/Interest';
import Demo from './Components/Present/Demo';
//import CarouselProvider from './Components/Navbar2/CarouselProvider';

import Main from './Components/Main/Main'
import Features from './Components/Features/Features';
// import ChartLine from './Components/New/ChartLine';
import Footer from './Components/New/Footer';

import './assets/styles.css';
import demo1 from './assets/images/demo1.jpg'
import demo2 from './assets/images/demo2.jpg'
import demo3 from './assets/images/demo3.jpg'
//<link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
// import { CarouselProvider } from './Components/CarouselProvider';
// import { Slider } from './Components/Slider';
// import { SliderInner } from './Components/SliderInner';
// import { LeftButton } from './Components/LeftButton';
// import { RightButton } from './Components/RightButton';
const items: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function App() {
  return (
    <div className="App" id="header">
      {/* <div data-spy="scroll" data-target=".fixed-top" /> */}

      <Slider images={[demo1, demo2, demo3]} />

      <Navbar2 />
      <Features />
      <Interest />
      <Demo />      
      <Footer/>
    </div>
  );
}

export default App;

// {/* <CarouselProvider
// itemsCount={10} //required
// visibleSlides={9} //max=itemsCount, min=1
// currentSlide={0} //max=itemsCount, min=0
// slideHeightCoeff={16/9} //slideWight/sliderHeight
// loop={true}
// innerPadding={16}
// >
// <LeftButton />
// <Slider>
//     {/* <SliderInner items={items} /> */}
//     <SliderInner items={items} />
// </Slider>
// <RightButton />
// </CarouselProvider> */}
