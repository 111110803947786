import React from 'react';
import { useState } from 'react'
import { SiConsul } from 'react-icons/si'
import { AiOutlineGlobal } from 'react-icons/ai'
import { BsPhoneVibrate } from 'react-icons/bs'
import { CgMenuGridO } from 'react-icons/cg'
// import logo from '../../assets/logo.png'
import icon1 from '../../assets/images/features-icon-1.svg'
import icon2 from '../../assets/images/features-icon-2.svg'
import icon3 from '../../assets/images/features-icon-3.svg'

//const Contact: React.FC = () => { 
const Demo = () => {

  return (

    <div id="demo" className="cards-1">
            <div className="container px-4 sm:px-8 xl:px-4">
                <h2 style={{margin:'10px'}}>Product Demonstration</h2>
                <div className="card">
                    <div className="card-image">
                        <img src={icon1} alt="alternative" />
                    </div>
                    <div className="card-body">
                        <a href="https://airline.butterfun.com"><h5 className="card-title">Airline</h5></a>
                        <p className="mb-4">Airline landing page</p>
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                        <img src={icon2} alt="alternative"/>
                    </div>
                    <div className="card-body">
                        {/* <a className="nav-link page-scroll" href="#ability">Ability</a> */}
                        <a href="https://demo1.butterfun.com"><h5 className="card-title">ERPs</h5></a>
                        <p className="mb-4">ERP in construction industry</p>
                    </div>
                </div>
            </div> 
        </div>
  );
}

export default Demo